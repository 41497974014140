import { Fragment, useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Input } from "antd";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { format } from "timeago.js";
import Loader from "./Loader";
import { useUserState, useUserDispatch } from "../../Contexts/UserContext";
import { Popconfirm } from "antd";
import RangeDateCalendar from "./RangeDateCalendar";
import NotificBellIconImg from "@images/notificbellicon.png";
import NotificLogoImg from "@images/notificlogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationContext } from "../../Contexts/NotificationContext";
import { UserContext } from "../../Contexts/UserContext";
import { useContext } from "react";
const Notifications = ({
  isLoader,
  setIsLoader,
  notificationList,
  setNotificationList,
  getNotifications,
}) => {
  const { user } = useUserState();
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [modalInputsData, setModalInputsData] = useState(null);
  const [negoDetail, setNegoDetail] = useState(null);
  const [modalInputError, setModalInputError] = useState(false);
  const [notifIsActive, SetNotifIsActive] = useState(null);
  const [negoCount, SetNegoCount] = useState(null);
  const [notificationId, setNotificationId] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [disabledDate, setDisabledDate] = useState(null);
  const [dateLocked, setDateLock] = useState(false);
  const [dateAccepted, setDateAccepted] = useState(false);
  const [isCheckDateOverlap, setIsCheckDateOverlap] = useState(false);
  const [visibleElements, setVisibleElements] = useState(9);
  const [ownerOfferedLeaseEndDate, setOwnerOfferedLeaseEndDate] =
    useState(null);
  const [ownerOfferedLeaseStartDate, setOwnerOfferedLeaseStartDate] =
    useState(null);
  const [currentModalNotification, setCurrentModalNotification] =
    useState(null);
  const [disabledDateRanges, setDisabledDateRanges] = useState([]);

  const [isExpired, setIsExpired] = useState(false);
  const [rejectBtnLoader, setRejectBtnLoader] = useState(false);
  const [cartLoader, setCartLoader] = useState(false);
  const [buttonVisibility, setButtonVisibility] = useState({});

  const { negotiationId, setNegotiationId, expiryTiming, setExpiryTiming } =
    useContext(UserContext);

  //type_data.relatedHouse.house_facility
  const notificationStatus = {
    in_progress: "in progress",
    tenant: {
      accept: "tenant_accept",
      reject: "tenant_reject",
    },
    home_owner: {
      accept: "owner_accept",
      reject: "owner_reject",
    },
  };
  function defaultBlockDate(data) {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((houseBooking) => {
      return {
        start: new Date(houseBooking.lease_start_date),
        end: new Date(houseBooking.lease_end_date),
      };
    });
  }

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };

  useEffect(() => {
    if (selectedStartDate == null && selectedEndDate == null) {
      setDateAccepted(true);
      setIsCheckDateOverlap(false);
    }
    if (selectedStartDate && selectedEndDate) {
      setIsCheckDateOverlap(
        checkDateOverlap(
          negoDetail?.negotiationRequest?.relatedHouse?.booking_data,
          selectedStartDate,
          selectedEndDate
        )
      );
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (!viewDetailModal) {
      setNegoDetail(null);
      setOwnerOfferedLeaseEndDate(null);
      setOwnerOfferedLeaseStartDate(null);
      setIsCheckDateOverlap(false);
    }
  }, [viewDetailModal]);

  const negotiationDetail = async (negoId, expiryTime) => {
    // setNegotiationId(negoId)

    setLoader(true);
    setNegoDetail();
    if (expiryTime) {
      const currentTimeUtc = new Date();
      const expiryTimeUtc = new Date(expiryTime);
      // if(expiryTimeUtc===currentTimeUtc){
      //   setNegotiationId(negoId)
      // }
      setIsExpired(currentTimeUtc > expiryTimeUtc);
    } else {
      setIsExpired(false);
    }

    const negoDetails = await axios.get(
      `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/house-negotiate-history/get/${negoId}`,
      {
        headers: headerJson,
      }
    );
    setNegoDetail(negoDetails.data.data);
    setDisabledDateRanges(
      defaultBlockDate(
        negoDetails?.data?.data?.negotiationRequest?.relatedHouse?.booking_data
      )
    );

    setLoader(false);
  };

  const autoReadNotification = async () => {
    await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/notification/read-notification`,
      {
        headers: headerJson,
      }
    );
    dispatch({
      type: "UPDATE_NOTIFICATION_COUNT",
      payload: 0,
    });
  };

  useEffect(() => {
    let intervalId;

    if (location.pathname === `/user_dash/${user?.currentRole}/notifications`) {
      // intervalId = setInterval(autoReadNotification, 2000);
      // window.addEventListener('scroll', autoReadNotification);
      window.addEventListener("click", autoReadNotification);
    }

    return () => {
      //  clearInterval(intervalId);
      // window.removeEventListener('scroll', autoReadNotification);
      window.removeEventListener("click", autoReadNotification);
    };
  }, [location.pathname]);

  const handleSendOffer = () => {
    if (!modalInputsData) {
      setModalInputError(true);
      return true;
    } else {
      setModalInputError(false);
      return false;
    }
  };

  function calculateDateDifference(startDate, endDate) {
    // Calculate the difference in milliseconds
    const differenceMs = endDate - startDate;

    // Convert milliseconds to days, adding 1 to include both start and end dates
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24)) + 1;

    // Calculate months and remaining days
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    let diffYear = endDate.getFullYear() - startDate.getFullYear();
    let diffMonth = endDate.getMonth() - startDate.getMonth();
    let months = diffYear * 12 + diffMonth;
    if (months < 0 || (months === 0 && endDay < startDay)) {
      months += 12;
    }

    let remainingDays = endDay - startDay + 1; // Including both start and end dates
    if (
      remainingDays ===
      new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate()
    ) {
      months++, (remainingDays = 0);
    }

    if (remainingDays < 0) {
      months--;
      const tempDate = new Date(endDate);
      tempDate.setMonth(endDate.getMonth() - 1);
      remainingDays =
        new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate() -
        startDay +
        endDay +
        1; // Including both start and end dates
    }

    return {
      days: differenceDays,
      months: months,
      remainingDays: remainingDays,
    };
  }

  function checkDateOverlap(dates, startdate2, endDate2) {
    // Convert second range dates to Date objects
    startdate2 = new Date(startdate2);
    endDate2 = new Date(endDate2);
    // Check if either of the second range dates is invalid
    if (
      isNaN(startdate2.getTime()) ||
      isNaN(endDate2.getTime()) ||
      startdate2 >= endDate2
    ) {
      throw new Error("Invalid or inverted date range provided.");
    }

    // Iterate over each date range in the array
    for (const dateRange of dates) {
      // Convert date range start and end dates to Date objects
      const startDate1 = new Date(dateRange.lease_start_date);
      const endDate1 = new Date(dateRange?.lease_end_date);

      // Check if either of the first range dates is invalid
      if (
        isNaN(startDate1.getTime()) ||
        isNaN(endDate1.getTime()) ||
        startDate1 >= endDate1
      ) {
        throw new Error(
          "Invalid or inverted date range provided in the array."
        );
      }

      // Check for overlap
      if (startDate1 < endDate2 && endDate1 > startdate2) {
        return true; // Overlap found, return true
      }
    }

    return false; // No overlap found
  }

  useEffect(() => {
    if (
      currentModalNotification?.user_role === "home_owner" &&
      negoDetail &&
      negoDetail?.negotiationRequest?.relatedHouse?.booking_data.length !== 0
    ) {
      if (ownerOfferedLeaseStartDate) {
        setIsCheckDateOverlap(
          checkDateOverlap(
            negoDetail?.negotiationRequest?.relatedHouse?.booking_data,
            ownerOfferedLeaseStartDate,
            ownerOfferedLeaseEndDate
          )
        );
      }
    }
  }, [
    currentModalNotification,
    negoDetail,
    ownerOfferedLeaseStartDate,
    ownerOfferedLeaseEndDate,
  ]);

  function formatDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is not provided
    const date = new Date(dateString);
    return date.toDateString();
  }

  useEffect(() => {
    setOwnerOfferedLeaseStartDate(
      formatDate(
        negoDetail?.owner_offered_lease_start_date ||
          negoDetail?.cust_offered_lease_start_date
      )
    );
    setOwnerOfferedLeaseEndDate(
      formatDate(
        negoDetail?.owner_offered_lease_end_date ||
          negoDetail?.cust_offered_lease_end_date
      )
    );
  }, [negoDetail]);

  useEffect(() => {
    if (user) {
      getNotifications();
    }
  }, [user]);

  const handleUpdate = async (
    negoId,
    counter_price,
    newNego,
    count,
    id,
    houseId,
    status,
    startDate,
    endDate,
    isDateLocked,
    isDateAccepted
  ) => {
    // setNegotiationId(negoId)

    try {
      const headers = {
        Authorization: user?.token,
      };
      const requestBody = {
        owner_offered_price: counter_price,
        owner_offered_lease_start_date: startDate,
        owner_offered_lease_end_date: endDate,
        is_date_locked: isDateLocked,
        is_date_accepted: isDateAccepted,
      };
      if (newNego) {
        const response = await axios.post(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/house-negotiate-history/create`,
          {
            cust_offered_price: counter_price,
            negotion_request_id: id,
            count: count + 1,
            house_id: houseId,
            cust_offered_lease_start_date: startDate,
            cust_offered_lease_end_date: endDate,

            cust_offered_no_person: negoDetail?.cust_offered_no_person,
            cust_name_pet: negoDetail?.cust_name_pet,
            cust_number_of_pets: negoDetail?.cust_name_pet,
          },
          {
            headers: headers,
          }
        );
      } else if (status) {
        setRejectBtnLoader(true);
        const response = await axios.put(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/house-price-negotiate/update/${id}`,
          {
            status: status,
            lease_start_date: startDate,
            lease_end_date: endDate,
          },
          {
            headers: headers,
          }
        );
        if (response) {
          setRejectBtnLoader(false);
          setViewDetailModal(false);
        }
      } else {
        const response = await axios.put(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/house-negotiate-history/update/${negoId}`,
          requestBody,
          {
            headers: headers,
          }
        );
      }
      getNotifications();
    } catch (err) {
      console.log("error", err);
    }
  };

  const loadMore = () => {
    setVisibleElements((prevVisibleElements) => prevVisibleElements + 9);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const handleChooseDatePlan = async (loc, houseId, dataId) => {
    setCartLoader(houseId);

    try {
      const negoDetails = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house-price-negotiate/get-by-negoId/${dataId}`,
        {
          headers: headerJson,
        }
      );
      try {
        const apiRes = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/cart/add-to-cart`,
          {
            house_id: houseId,
            lease_start_date: negoDetails?.data?.data[0]?.lease_start_date,
            lease_end_date: negoDetails?.data?.data[0]?.lease_end_date,
            negotiation_request_id: dataId,
            parent_cart_id: negoDetails?.data?.data[0]?.parent_cart_id,
            // parent_cart_id:orderId
          },
          {
            headers: headerJson,
          }
        );
        setCartLoader("");
        navigate("/" + loc, {
          state: {
            houseId: houseId,
            cartId: apiRes?.data?.data?.cartId,
            startDate: negoDetails?.data?.data[0]?.lease_start_date,
            endDate: negoDetails?.data?.data[0]?.lease_end_date,
          },
        });
      } catch (err) {
        setCartLoader("");
        console.log("Error while hitting add to cart API", err?.message);
      }
    } catch (error) {
      setCartLoader("");
      console.log(
        "Error while getting nego house data when we adding the house in cart",
        error?.message
      );
    }
  };

  // owner accept the tenant extended time when tenant not responding within 30 min

  const ownerAccepForExtendTime = async (negoId, id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: user?.token ?? "", // Provide a fallback if the token is undefined
    };
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house-price-negotiate/update/extend-time/${negoId}`,
        {
          method: "PUT",
          headers,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await response.json(); // Parse the JSON response
      setButtonVisibility((prev) => ({ ...prev, [id]: true }));
    } catch (error) {
      console.error("Error updating negotiation:", error.message || error);
    }
  };
  const onRejectHandle = async (negoId, id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/house-price-negotiate/expire/homeowner/${negoId}`,
      headers: {
        Authorization: user?.token,
      },
    };

    axios
      .request(config)
      .then(() => {
        setButtonVisibility((prev) => ({ ...prev, [id]: true }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <style>
        {`
          .ant-picker-dropdown{
            z-index: 1056;
        }


        .ant-picker.ant-picker-range.css-dev-only-do-not-override-i46qwz {
          margin: 10px 0;
      }

      .offr_price_label {
        padding-bottom: 7px;
    }
      
        `}
      </style>

      <div className="main-box">
        <div className="content-header">
          <h3>Notifications</h3>
        </div>
        <div className="notific_head">
          <div className="notific_cont">
            {notificationList?.length > 0 ? (
              notificationList
                ?.slice(0, visibleElements)
                ?.map((notification, key) => (
                  <>
                    <div key={key} className="notific_cont_inner">
                      <div className="notific_inner">
                        <div className="notific_icon">
                          <img src={NotificBellIconImg} alt="notificbellicon" />
                        </div>
                        <div
                          className="notific_sub_cont"
                          type="button"
                          onClick={() => {
                            SetNotifIsActive(notification.is_active);
                            SetNegoCount(notification.count);
                            if (notification.is_active) {
                              // SetNotifIsActive(notification.is_active);
                              SetNegoCount(notification.count);
                              setNotificationId(notification.type_data_id);
                              return "";
                            }
                           if (
                             notification?.type === "HousePriceNegotiateRequest"
                           ) {
                             // Set view detail modal based on role and notification details
                             const shouldShowModal =
                               user?.currentRole !== "tenant" ||
                               !["owner_accept", "tenant_accept"].includes(
                                 notification?.type_data?.status
                               ) ||
                               notification?.user_role !== null ||
                               notification?.count !== 6;

                             setViewDetailModal(shouldShowModal);

                             // Call negotiationDetail function
                             negotiationDetail(
                               notification?.type_data_id,
                               notification?.expiry_time
                             );
                           }
                          }}
                        >
                          {console.log("notification",notification)}
                          <p>{(notification.message)}</p>
                          {notification.type === "HousePriceNegotiateRequest" &&
                            notification.count === 9 &&
                            notification.is_active &&
                            !buttonVisibility[notification?.id] &&
                            new Date() <= new Date(notification.expiry_time) &&
                            (user.currentRole === "home_owner" || user.currentRole === null)  && (
                              <div
                                className="acc_duo_buttons"
                                key={notification?.id}
                              >
                                <button
                                  className="druyp-btn"
                                  onClick={() =>
                                    ownerAccepForExtendTime(
                                      notification?.type_data_id,
                                      notification?.id
                                    )
                                  }
                                >
                                  Accept
                                </button>
                                
                                <button
                                  className="druyp-btn druyp_btn_disbled"
                                  onClick={() =>
                                    onRejectHandle(
                                      notification?.type_data_id,
                                      notification?.id
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          {notification?.type_data_id &&
                          notification.count != 9 &&
                          notification.is_active ? (
                            <div className="notific_option">
                              <img src={NotificLogoImg} alt="notificlogo" />
                              <div className="notific_btn">
                                {/* <!-- Button trigger modal --> */}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setViewDetailModal(true);
                                    negotiationDetail(
                                      notification?.type_data_id,
                                      notification?.expiry_time
                                    );
                                    setCurrentModalNotification(notification);
                                  }}
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {user?.currentRole === "tenant" &&
                            // ["owner_accept", "tenant_accept"].includes(
                            //   notification?.type_data?.status
                            // ) &&
                            notification?.user_role === null &&
                            notification?.count === 6 &&
                            notification?.type ===
                              "HousePriceNegotiateRequest" && (
                              <div className="notific_btn">
                                { new Date() >
                                new Date(notification?.expiry_time) ? (
                                 !notification?.type_data?.cart_id ? <span className="expired_btn">Expired</span> : null
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={
                                      cartLoader ===
                                      notification?.type_data?.house_id
                                    }
                                    onClick={() =>
                                      handleChooseDatePlan(
                                        notification?.type_data?.parent_cart_id
                                          ? "cleaning-package"
                                          : notification?.type_data
                                              ?.relatedHouse?.house_facility ===
                                            "Fully Furnished"
                                          ? "cleaning-package"
                                          : "furniture_package",
                                        notification?.type_data?.house_id,
                                        notification?.type_data_id
                                      )
                                    }
                                  >
                                    {notification?.type_data?.parent_cart_id
                                      ? "Customize your cleaning"
                                      : notification?.type_data?.relatedHouse
                                          ?.house_facility === "Fully Furnished"
                                      ? "Customize your cleaning"
                                      : "Pick your furniture packages"}{" "}
                                    {cartLoader ===
                                      notification?.type_data?.house_id && (
                                      <Spinner />
                                    )}
                                  </button>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                      <h4>{format(notification.createdAt)}</h4>
                    </div>
                    <hr />
                  </>
                ))
            ) : (
              <span>There is no Notifications</span>
            )}
            {visibleElements < notificationList?.length && (
              <button className="druyp-link" onClick={loadMore}>
                View More
              </button>
            )}
          </div>
        </div>
      </div>
      {/* View details modal */}
      <Modal
        // backdrop="false"
        show={viewDetailModal}
        onHide={() => {
          setViewDetailModal(false);
          setModalInputError(false);
        }}
        size="lg"
        className="nego-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {loader ? (
            <>
              {/* <Loader /> */}
              <div className="loading-data">
                <Spinner></Spinner>
              </div>
            </>
          ) : (
            <div className="furnit_booking_cont">
              <div className="furnit_delux notific_modal_cont">
                <div className="delux_img">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: ".5rem",
                    }}
                    src={`${import.meta.env.VITE_APP_BASE_URL}${
                      negoDetail?.negotiationRequest?.relatedHouse
                        ?.houseImages[0]?.imageUrl
                    }`}
                    alt="delux_img"
                  />
                </div>

                <div className="furnit_delux_cont notific_modal_cont_inner">
                  {isExpired ? (
                    <h6 style={{ color: "red" }}>This offer has expired</h6>
                  ) : (
                    ""
                  )}
                  <h4>{negoDetail?.relatedHouse?.name}</h4>
                  <h4 style={{ display: "flex" }}>
                    Offered lease period: &nbsp;
                    <div
                      style={{
                        color: negoDetail?.negotiationRequest?.is_date_locked
                          ? "#d9a200"
                          : negoDetail?.negotiationRequest?.is_date_accepted
                          ? "green"
                          : "",
                      }}
                    >
                      {/* {negoDetail?.owner_offered_lease_start_date
                        ? new Date(
                            negoDetail?.owner_offered_lease_start_date
                          ).toDateString()
                        : new Date(
                            negoDetail?.cust_offered_lease_start_date
                          ).toDateString()}{" "}
                      to{" "}
                      {negoDetail?.owner_offered_lease_end_date
                        ? new Date(
                            negoDetail?.owner_offered_lease_end_date
                          ).toDateString()
                        : new Date(
                            negoDetail?.cust_offered_lease_end_date
                          ).toDateString()} */}
                      {ownerOfferedLeaseStartDate} to {ownerOfferedLeaseEndDate}
                    </div>
                  </h4>
                  <h4>
                    Number of Person :
                    {negoDetail?.cust_offered_no_person
                      ? negoDetail?.cust_offered_no_person
                      : "N/A"}
                  </h4>
                  <h4>
                    Number of Pets :
                    {negoDetail?.cust_number_of_pets
                      ? negoDetail?.cust_number_of_pets
                      : "N/A"}
                  </h4>
                  <h4>
                    Pets :
                    {negoDetail?.cust_name_pet
                      ? negoDetail?.cust_name_pet
                      : "N/A"}
                  </h4>
                  <h4>
                    Period (Month and Days):{" "}
                    {(function calculateMonthsAndDays(
                      startDate = negoDetail?.owner_offered_lease_start_date
                        ? new Date(negoDetail?.owner_offered_lease_start_date)
                        : new Date(negoDetail?.cust_offered_lease_start_date),
                      endDate = negoDetail?.owner_offered_lease_end_date
                        ? new Date(negoDetail?.owner_offered_lease_end_date)
                        : new Date(negoDetail?.cust_offered_lease_end_date)
                    ) {
                      // Calculate the difference in milliseconds
                      let { months, remainingDays } = calculateDateDifference(
                        startDate,
                        endDate
                      );
                      // endDate.getTime() - startDate.getTime();
                      return `${months} ${
                        months == 1 ? `Month` : `Months`
                      } ${remainingDays} ${
                        remainingDays == 1 ? `Day` : `Days`
                      }`;
                    })()}
                  </h4>
                  <h4>
                    Current Price: $
                    {negoDetail?.negotiationRequest?.relatedHouse
                      ?.monthly_price +
                      negoDetail?.negotiationRequest?.relatedHouse
                        ?.total_utilities_price}
                  </h4>
                  <h4>
                    Proposed Price: $
                    {negoDetail?.owner_offered_price
                      ? negoDetail?.owner_offered_price
                      : negoDetail?.cust_offered_price}
                  </h4>
                  <hr />
                  {notifIsActive &&
                  negoCount <= 4 &&
                  (negoCount == 4
                    ? user?.currentRole !== "tenant" ||
                      currentModalNotification?.user_role === "home_owner"
                    : true) ? (
                    <>
                      {(negoDetail?.counter_price &&
                        parseInt(negoDetail?.counter_price) > 1) ||
                      negoDetail?.status?.includes("accept") ||
                      negoDetail?.status?.includes("reject")
                        ? ""
                        : !isExpired && (
                            <form className="row g-3">
                              <div className="col-12">
                                <label
                                  className="form-label"
                                  htmlFor="offerPrice"
                                >
                                  Offer :
                                </label>
                                <Input
                                  id="offerPrice"
                                  type="text"
                                  addonBefore="$"
                                  addonAfter="/month"
                                  placeholder="Enter your Price"
                                  value={modalInputsData?.negotiationPrice}
                                  onKeyPress={(e) => {
                                    if (
                                      !/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) =>
                                    setModalInputsData(e.target.value)
                                  }
                                />
                                <br />
                                {negoDetail?.negotiationRequest
                                  ?.is_date_locked ? (
                                  <span style={{ color: "#d9a200" }}>
                                    Dates Are Locked By The Owner!
                                  </span>
                                ) : negoDetail?.negotiationRequest
                                    ?.is_date_accepted ? (
                                  <span style={{ color: "green" }}>
                                    Dates Are Accepted By The Owner!
                                  </span>
                                ) : (
                                  <>
                                    <label className="form-label">
                                      Negotiate Dates :
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <RangeDateCalendar
                                        size={"large"}
                                        selectedStartDate={selectedStartDate}
                                        setSelectedStartDate={
                                          setSelectedStartDate
                                        }
                                        selectedEndDate={selectedEndDate}
                                        setSelectedEndDate={setSelectedEndDate}
                                        disabledDate={disabledDate}
                                        disabledDateRanges={disabledDateRanges}
                                        // disableMonths={
                                        //   negoDetail?.negotiationRequest
                                        //     .relatedHouse.min_lease_period
                                        // }
                                        disableMonths={1}
                                        setDisabledDate={setDisabledDate}
                                        houseType={
                                          negoDetail?.negotiationRequest
                                            ?.type_data?.relatedHouse
                                            ?.house_facility
                                        }
                                      />
                                      {user?.currentRole == "home_owner" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            marginLeft: "1.5rem",
                                          }}
                                        >
                                          <Form.Check
                                            value={dateLocked}
                                            onChange={() => {
                                              setDateLock((prev) => {
                                                return !prev;
                                              });
                                            }}
                                            aria-label="option 1"
                                          />{" "}
                                          <div style={{ marginLeft: "0.5rem" }}>
                                            Lock Dates
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                )}
                                <div className="already_booked_house_outer">
                                  <div>
                                    {isCheckDateOverlap &&
                                    currentModalNotification?.user_role ===
                                      "home_owner" &&
                                    negoDetail &&
                                    negoDetail?.negotiationRequest?.relatedHouse
                                      ?.booking_data.length !== 0 ? (
                                      <>
                                        <h3>
                                          There are already{" "}
                                          {
                                            negoDetail.negotiationRequest
                                              .relatedHouse.booking_data.length
                                          }{" "}
                                          booking for this house.
                                        </h3>
                                        <div className="already_booked_house_inner">
                                          {negoDetail.negotiationRequest.relatedHouse.booking_data.map(
                                            (item, i) => (
                                              <div
                                                key={item.id}
                                                className="single_booking_house"
                                              >
                                                <p style={{ margin: "0" }}>
                                                  <span>{i + 1}: </span>
                                                  <span>House Booked</span>
                                                  {" from "}
                                                  <span style={{ margin: "0" }}>
                                                    {new Date(
                                                      item.lease_start_date
                                                    ).toLocaleDateString(
                                                      "en-US"
                                                    )}{" "}
                                                    to{" "}
                                                    {new Date(
                                                      item.lease_end_date
                                                    ).toLocaleDateString(
                                                      "en-US"
                                                    )}
                                                  </span>
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                {modalInputError &&
                                  !modalInputsData?.negotiationPrice && (
                                    <span className="text-danger">
                                      Please enter your Price!
                                    </span>
                                  )}
                              </div>
                              {isCheckDateOverlap ? (
                                <span className="small">
                                  *This property has already been leased out
                                  during the specified lease period.
                                </span>
                              ) : null}
                              <div className="col-12"></div>
                              <div className="modal_btn">
                                <>
                                  {user?.currentRole == "tenant" &&
                                  negoDetail?.negotiationRequest
                                    ?.is_date_locked ? (
                                    <div className="notific_btn">
                                      <Popconfirm
                                        title="Reject Offer"
                                        description="Are you sure you want to reject the offer?"
                                        onConfirm={() => {
                                          handleUpdate(
                                            null,
                                            null,
                                            null,
                                            null,
                                            notificationId,
                                            null,
                                            user?.currentRole == "tenant"
                                              ? notificationStatus.tenant.reject
                                              : notificationStatus.home_owner
                                                  .reject
                                          );
                                        }}
                                        // onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <button
                                          type="button"
                                          disabled={rejectBtnLoader}
                                          // onClick={() => {
                                          //   handleUpdate(
                                          //     null,
                                          //     null,
                                          //     null,
                                          //     null,
                                          //     notificationId,
                                          //     null,
                                          //     user?.currentRole == "tenant"
                                          //       ? notificationStatus.tenant.reject
                                          //       : notificationStatus.home_owner.reject
                                          //   );
                                          // }}
                                        >
                                          Reject{" "}
                                          {rejectBtnLoader && <Spinner />}
                                        </button>
                                      </Popconfirm>

                                      {/* <button
                                    type="button"
                                    onClick={() => {
                                      handleUpdate(
                                        null,
                                        null,
                                        null,
                                        null,
                                        notificationId,
                                        null,
                                        user?.currentRole == "tenant"
                                          ? notificationStatus.tenant.reject
                                          : notificationStatus.home_owner.reject
                                      );
                                    }}
                                  >
                                    Reject
                                  </button> */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>

                                <div className="notific_btn">
                                  <button
                                    type="button"
                                    style={
                                      isCheckDateOverlap || rejectBtnLoader
                                        ? {
                                            opacity: "0.5",
                                            border: "1px solid #818181",
                                            background: "#fff",
                                            color: "#818181",
                                          }
                                        : {}
                                    }
                                    disabled={
                                      isCheckDateOverlap || rejectBtnLoader
                                    }
                                    onClick={() => {
                                      if (handleSendOffer()) return "";
                                      if (negoDetail?.owner_offered_price) {
                                        handleUpdate(
                                          negoDetail?.id,
                                          modalInputsData,
                                          "new_nego",
                                          negoCount,
                                          notificationId,
                                          negoDetail.negotiationRequest
                                            .relatedHouse.id,
                                          null,
                                          selectedStartDate ||
                                            negoDetail?.owner_offered_lease_start_date ||
                                            negoDetail?.cust_offered_lease_start_date,
                                          selectedEndDate ||
                                            negoDetail?.owner_offered_lease_end_date ||
                                            negoDetail?.cust_offered_lease_end_date,
                                          null
                                        );
                                      } else {
                                        handleUpdate(
                                          negoDetail?.id,
                                          modalInputsData,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          selectedStartDate,
                                          selectedEndDate,
                                          dateLocked,
                                          dateAccepted
                                        );
                                      }

                                      setViewDetailModal(false);
                                    }}
                                  >
                                    Counteroffer
                                  </button>

                                  <Popconfirm
                                    title="Accept Offer"
                                    description="Are you sure you want to accept the offer?"
                                    onConfirm={() => {
                                      handleUpdate(
                                        null,
                                        null,
                                        null,
                                        null,
                                        notificationId,
                                        null,
                                        user?.currentRole == "tenant"
                                          ? notificationStatus.tenant.accept
                                          : notificationStatus.home_owner
                                              .accept,
                                        selectedStartDate ||
                                          negoDetail?.owner_offered_lease_start_date ||
                                          negoDetail?.cust_offered_lease_start_date,
                                        selectedEndDate ||
                                          negoDetail?.owner_offered_lease_end_date ||
                                          negoDetail?.cust_offered_lease_end_date
                                      );
                                      setViewDetailModal(false);
                                    }}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <button
                                      type="button"
                                      style={
                                        isCheckDateOverlap || rejectBtnLoader
                                          ? {
                                              opacity: "0.5",
                                              border: "1px solid #818181",
                                              background: "#fff",
                                              color: "#818181",
                                            }
                                          : {}
                                      }
                                      disabled={
                                        isCheckDateOverlap || rejectBtnLoader
                                      }
                                    >
                                      Accept
                                    </button>
                                  </Popconfirm>
                                  {/* {negoCount == 4 &&
                              user?.currentRole == "tenant" ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleUpdate(
                                      null,
                                      null,
                                      null,
                                      null,
                                      notificationId,
                                      null,
                                      user?.currentRole == "tenant"
                                        ? notificationStatus.tenant.reject
                                        : notificationStatus.home_owner.reject
                                    );
                                    setViewDetailModal(false);
                                  }}
                                >
                                  Reject
                                </button>
                              ) : (
                                ""
                              )} */}
                                </div>
                              </div>
                            </form>
                          )}
                    </>
                  ) : (
                    ""
                  )}

                  {negoCount == 4 &&
                  currentModalNotification?.user_role == "tenant" &&
                  notifIsActive ? (
                    <>
                      <Popconfirm
                        title="Accept Offer"
                        description="Are you sure you want to accept the offer?"
                        onConfirm={() => {
                          handleUpdate(
                            null,
                            null,
                            null,
                            null,
                            notificationId,
                            null,
                            user?.currentRole == "tenant"
                              ? notificationStatus.tenant.accept
                              : notificationStatus.home_owner.accept,
                            selectedStartDate ||
                              negoDetail?.owner_offered_lease_start_date ||
                              negoDetail?.cust_offered_lease_start_date,
                            selectedEndDate ||
                              negoDetail?.owner_offered_lease_end_date ||
                              negoDetail?.cust_offered_lease_end_date
                          );
                          setViewDetailModal(false);
                        }}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button
                          type="button"
                          disabled={isCheckDateOverlap || rejectBtnLoader}
                          className="accept_last"
                        >
                          Accept
                        </button>
                      </Popconfirm>

                      <Popconfirm
                        title="Reject Offer"
                        description="Are you sure you want to reject the offer?"
                        onConfirm={() => {
                          handleUpdate(
                            null,
                            null,
                            null,
                            null,
                            notificationId,
                            null,
                            user?.currentRole == "tenant"
                              ? notificationStatus.tenant.reject
                              : notificationStatus.home_owner.reject
                          );
                          setViewDetailModal(false);
                        }}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button
                          type="button"
                          className="reject_last"
                          disabled={rejectBtnLoader}
                          // onClick={() => {
                          //   handleUpdate(
                          //     null,
                          //     null,
                          //     null,
                          //     null,
                          //     notificationId,
                          //     null,
                          //     user?.currentRole == "tenant"
                          //       ? notificationStatus.tenant.reject
                          //       : notificationStatus.home_owner.reject
                          //   );
                          //   setViewDetailModal(false);
                          // }}
                        >
                          Reject {rejectBtnLoader && <Spinner />}
                        </button>
                      </Popconfirm>

                      {/* <button
                        type="button"
                        className="reject_last"
                        onClick={() => {
                          handleUpdate(
                            null,
                            null,
                            null,
                            null,
                            notificationId,
                            null,
                            user?.currentRole == "tenant"
                              ? notificationStatus.tenant.reject
                              : notificationStatus.home_owner.reject
                          );
                          setViewDetailModal(false);
                        }}
                      >
                        Reject
                      </button> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Notifications;
