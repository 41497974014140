import React, { useState, useCallback,useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import RangeDateCalendar from "../common/RangeDateCalendar";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserState } from "../../Contexts/UserContext";

export default function NegotiationModal({ data, setIsDatesBlockedForExtend }) {
  const [showPremium, setShowPremium] = useState(false);
  const { user } = useUserState();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [offerPrice, setOfferPrice] = useState(
    parseInt(Number(data?.current_lease))
  );
  
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const navigate = useNavigate();

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "cust_offered_no_person") {
      setNumberOfPeople(value);
    }
  }, []);

  const defaultBlockDate = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((houseBooking) => ({
      start: new Date(houseBooking.lease_start_date),
      end: new Date(houseBooking.lease_end_date),
    }));
  };

  const [disabledDateRanges, setDisabledDateRanges] = useState(
    defaultBlockDate(data?.disabledDates || [])
  );

  const removeObject = (obj, startDate, endDate) => {
    return obj.filter(
      (item) =>
        !(
          item.lease_start_date === startDate && item.lease_end_date === endDate
        )
    );
  };

  const checkDateOverlap = (dates, startDate2, endDate2) => {
    startDate2 = new Date(startDate2);
    endDate2 = new Date(
      new Date(endDate2).setDate(new Date(endDate2).getDate() + 30)
    );

    if (
      isNaN(startDate2.getTime()) ||
      isNaN(endDate2.getTime()) ||
      startDate2 >= endDate2
    ) {
      throw new Error("Invalid or inverted date range provided.");
    }

    for (const dateRange of dates) {
      const startDate1 = new Date(dateRange.lease_start_date);
      const endDate1 = new Date(dateRange.lease_end_date);

      if (
        isNaN(startDate1.getTime()) ||
        isNaN(endDate1.getTime()) ||
        startDate1 >= endDate1
      ) {
        throw new Error(
          "Invalid or inverted date range provided in the array."
        );
      }

      if (startDate1 < endDate2 && endDate1 > startDate2) {
        return true;
      }
    }

    return false;
  };

  const [isCheckDateOverlap] = useState(() => {
    try {
      return checkDateOverlap(
        removeObject(
          data?.disabledDates || [],
          data.lease_start_date,
          data.lease_end_date
        ),
        data.lease_start_date,
        data.lease_end_date
      );
    } catch (err) {
      console.error(err);
      return false;
    }
  });

  useEffect(()=>{
    if(setIsDatesBlockedForExtend){
      setIsDatesBlockedForExtend(isCheckDateOverlap)
    }
  },[isCheckDateOverlap])

  const sendNegoOffer = async () => {
    // Validate fields
    const newErrors = {};
    if (!data?.house_id?.trim()) {
      newErrors.houseId = "House ID is required";
    }
    if (!selectedStartDate) {
      newErrors.selectedStartDate = "*Start Date is required";
    }
    if (!selectedEndDate) {
      newErrors.selectedEndDate = "*End Date is required";
    }
    if (!offerPrice) {
      newErrors.offerPrice = "*Offer Price is required";
    }
    if (offerPrice && isNaN(Number(offerPrice))) {
      newErrors.offerPrice = "*Offer Price must be a number";
    }

    setValidationErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    setIsLoader(true);

    try {
      await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/house-price-negotiate/create`,
        {
          house_id: data?.house_id,
          cust_offered_lease_start_date: selectedStartDate,
          cust_offered_lease_end_date: selectedEndDate,
          cust_offered_price: offerPrice,
          status: "in_progress",
          is_extended_negotiation: true,
          parent_cart_id: data?.order_id,
        },
        { headers: headerJson }
      );
      setShowPremium(false);
      navigate("/user_dash/tenant/negotiations");
    } catch (err) {
      setErrMessage(err.response?.data?.message || "An error occurred");
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <>
      <div className="dir_btn">
        {isCheckDateOverlap ? (
          ""
        ) : (
          <>
            <button
              variant="primary"
              className="ms-3"
              onClick={() => setShowPremium(true)}
            >
              Extend
            </button>
          </>
        )}
      </div>
      <Modal
        show={showPremium}
        size="lg"
        onHide={() => {
          setShowPremium(false);
          setOfferPrice(parseInt(Number(data?.current_lease)));
          setValidationErrors({});
        }}
        className="nego-modal"
        style={{ zIndex: "1050" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Extend Your Lease Period</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label>House Lease Price : ${data?.house_lease}</label>
            </div>
            <div className="col-md-6">
              <label>current Lease Price : ${data?.current_lease}</label>
            </div>
            <div className="col-md-6">
              <label>Select Date:</label>
              <RangeDateCalendar
                size="large"
                selectedStartDate={data?.lease_end_date}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                disabledDateRanges={disabledDateRanges}
                disableMonths={data?.min_lease_period || 1}
                setDisabledDateRanges={setDisabledDateRanges}
                houseType={"Extended"}
              />
              <div className="sap-val-error text-danger mb-0 mt-1">
                {validationErrors.selectedStartDate}
              </div>
              <div className="sap-val-error text-danger mb-0 mt-1">
                {validationErrors.selectedEndDate}
              </div>
            </div>
            <div className="col-md-6">
              <label className="offr_price_label pb-0 mt-3 mt-md-0">
                Offer Price:
              </label>
              <Input
                className="d-block"
                addonBefore="$"
                addonAfter="/month"
                value={offerPrice}
                onChange={(e) => setOfferPrice(e.target.value)}
              />
              <div className="sap-val-error text-danger mb-0 mt-1">
                {validationErrors.offerPrice}
              </div>
            </div>
          </div>

          <div className="sap-val-error text-danger mb-0 mt-1">
            {errMessage}
          </div>
          <button
            type="button"
            style={{
              borderRadius: "45px",
              padding: "12px",
              display: "block",
              margin: "10px 0px 0px",
              textAlign: "center",
              fontWeight: "700",
              color: "#fff",
              width: "170px",
              marginLeft: "auto",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#0474BA",
            }}
            onClick={sendNegoOffer}
            disabled={isLoader}
          >
            Send Offer
            {isLoader && <Spinner animation="border" size="sm" />}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
