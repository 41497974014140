import { Fragment } from "react";
import Select from "react-select";
import { Modal, Spinner } from "react-bootstrap";
import { Input } from "antd";
import Tickmark from "../../../assets/images/tickmark.svg";
import DBed from "../../../assets/images/D-Bed.svg";
import Watersupp from "../../../assets/images/Water-supp.svg";
import GatedSec from "../../../assets/images/gated-sec.svg";
import Facingside from "../../../assets/images/facing-side.svg";
import Parking from "../../../assets/images/parking.svg";
import floor from "../../../assets/images/floor.svg";
import LucideDog from "../../../assets/images/lucide_dog.svg";
import Call from "../../../assets/images/call.svg";
import Snowflake from "../../../assets/images/snowflake.svg";
import Wifi from "../../../assets/images/Wifi.svg";
import Lift from "../../../assets/images/lift.svg";
import Server from "../../../assets/images/server.svg";
import Shield from "../../../assets/images/shield.svg";
import Rain from "../../../assets/images/rain.svg";
import Trees from "../../../assets/images/Trees.svg";
import Playground from "../../../assets/images/playground.svg";
import Fire from "../../../assets/images/Fire.svg";
import { useParams } from "react-router-dom";
import RulerSvg from "../../../assets/images/ruler.svg";
import BathTubSvg from "../../../assets/images/Bathtub.svg";
import BedImage from "../../../assets/images/Bed.svg";
import RangeDateCalendar from "../../common/RangeDateCalendar";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../../../Contexts/UserContext";
import { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import all from "store/storages/all";
import NegotiationModal from "../../negotationModal/NegotiationModal";

const PropertyDetailSec = ({ data }) => {
  const { user, isLoggedIn } = useUserState();
  const [negoPriceModalData, setNegoPriceModalData] = useState("");
  const [amenitiesList, setAmenitiesList] = useState([]);
  const navigate = useNavigate();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [disabledDate, setDisabledDate] = useState(null);
  const [disabledDateRanges, setDisabledDateRanges] = useState([]);
  const [showPremium, setShowPremium] = useState(false);
  const [offerPrice, setOfferPrice] = useState(null);
  const [negoData, setNegoData] = useState(null);
  const [show, setShow] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [custValidErr, setCustValidErr] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [addToCartMsg, setAddToCartMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const [allUtiltiy, setAllUtility] = useState([]);
  const [utilityPrice, setUtiltiyPrice] = useState(null);
  const [numberOfPeople, setNumberOfPeople] = useState(null);
  const [numberOfPet, setNumberOfPet] = useState("");

  const [animalType, setAnimalType] = useState("");
  const [otherAnimal, setOtherAnimal] = useState("");

  const [ispetAllowed, setAllowedPet] = useState("");
  const [selectedAnimal, setAnimalSelectOptons] = useState([]);
  const [isDatesBlockedForExtend , setIsDatesBlockedForExtend] = useState(false)
  const options = [
    { value: "Dog", label: "Dog" },
    { value: "Cat", label: "Cat" },
    { value: "Other", label: "Other" },
  ];

  const handleOtherAnimalChange = (e) => {
    let inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z,\s]/g, '');
    if (filteredValue.length <= 10) {
      setOtherAnimal(filteredValue);
    }
  };

  const [selectedStartDateWithoutNego, setSelectedStartDateWithoutNego] =
    useState(null);
  const [selectedEndDateWithoutNego, setSelectedEndDateWithoutNego] =
    useState(null);
  const [disabledDatesWithoutNego, setDisabledDatesWithoutNego] = useState([]);
  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };
  useEffect(() => {
    var url = window.location.href;
    var parts = url.split("/");

    var lastPart = parts[parts.length - 1];
    var number = parseInt(lastPart);

    const housePrice = async () => {
      const negoDetails = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house-price-negotiate/get-by-house/${number}`,
        {
          headers: headerJson,
        }
      );
      // if (
      //   negoDetails.data.data[0]?.lease_start_date != null &&
      //   negoDetails.data.data[0]?.lease_end_date != null
      // ) {
      //   localStorage.setItem(
      //     "fromDate",
      //     negoDetails.data.data[0].lease_start_date
      //   );
      //   localStorage.setItem("toDate", negoDetails.data.data[0].lease_end_date);
      // }
     // debugger
      setNegoData(negoDetails.data.data[0]);
    };
    if (user) {
      housePrice();
    }
  }, [user]);
  useEffect(() => {
    if (data && data.booking_data && data.booking_data.length > 0) {
      setDisabledDateRanges(defaultBlockDate(data.booking_data));
    }
  }, [data]);
  const { houseId } = useParams();

  let allAnimal = selectedAnimal
    .filter((data) => data.value !== "Other")
    .reduce((allData, current) => allData + current.value + ",", "");

  const sendNegoOffer = async () => {
    // Validate fields
    const newErrors = {};
    if (!houseId.trim()) {
      newErrors.houseId = "House ID is required";
    }
    if (!selectedStartDate) {
      newErrors.selectedStartDate = "*Start Date is required";
    }
    if (!selectedEndDate) {
      newErrors.selectedEndDate = "*End Date is required";
    }
    if (!offerPrice) {
      newErrors.offerPrice = "*Offer Price is required";
    }
    if (offerPrice && !(!isNaN(Number(offerPrice)) && Number(offerPrice) > 0)) {
      newErrors.offerPrice = "*Offer Price is only number";
    }
    if (!numberOfPeople) {
      newErrors.numberOfPeople = "*Number Of people is required";
    }

    // if(!numberOfPet){

    //   newErrors.numberOfPet="*Number of pet is required"
    // }

    // if(!selectedAnimal){

    //   newErrors.selectedAnimal="*Selection is required"
    // }

    setValidationErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }
    setIsLoader(true);

    try {
      const res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/house-price-negotiate/create`,
        {
          house_id: houseId,
          cust_offered_lease_start_date: selectedStartDate,
          cust_offered_lease_end_date: selectedEndDate,
          cust_offered_price: offerPrice,
          status: "in_progress",
          cust_offered_no_person: numberOfPeople,

          cust_name_pet: allAnimal + otherAnimal,

          cust_number_of_pets: numberOfPet,
        },
        { headers: headerJson }
      );
      if (res) {
        setShowPremium(false);
      }
      navigate("/user_dash/tenant/negotiations");
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      setErrMessage(err.response.data.message);
    }
  };

  //number of people filed
  const handleInputChange = (e) => {
    //const value = e.target.value;
    const { name, value } = event.target;

    // Ensure the value is not greater than two digits
    if (value.length <= 2 && value >= 0) {
      setNumberOfPeople(value);

      if (name === "cust_offered_no_person") {
        setValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (value.trim()) {
            delete newErrors.numberOfPeople;
          }
          return newErrors;
        });
      }
    }
  };

  //number of animal  filed
  const handleNumberOfAnimalChange = (e) => {
    const value = e.target.value;
    // Ensure the value is not greater than two digits
    if (value.length <= 2 && value >= 0) {
      setNumberOfPet(value);
    }
  };

  // =========================================================

  const handleChange = (selected) => {
      if (selected.length > numberOfPet) {
        return;
      }
    setAnimalSelectOptons(selected);


    if (!selected.find((option) => option.value === "Other")) {
      setOtherAnimal("");
    }
  };

  function calculateDateDifference(startDate, endDate) {
    // Calculate the difference in milliseconds
    const differenceMs = endDate - startDate;

    // Convert milliseconds to days, adding 1 to include both start and end dates
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24)) + 1;

    // Calculate months and remaining days
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    let diffYear = endDate.getFullYear() - startDate.getFullYear();
    let diffMonth = endDate.getMonth() - startDate.getMonth();
    let months = diffYear * 12 + diffMonth;
    if (months < 0 || (months === 0 && endDay < startDay)) {
      months += 12;
    }

    let remainingDays = endDay - startDay + 1; // Including both start and end dates
    if (
      remainingDays ===
      new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate()
    ) {
      months++, (remainingDays = 0);
    }

    if (remainingDays < 0) {
      months--;
      const tempDate = new Date(endDate);
      tempDate.setMonth(endDate.getMonth() - 1);
      remainingDays =
        new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate() -
        startDay +
        endDay +
        1; // Including both start and end dates
    }

    return {
      days: differenceDays,
      months: months,
      remainingDays: remainingDays,
    };
  }
  function defaultBlockDate(data) {
    return data.map((houseBooking) => {
      return {
        start: new Date(houseBooking.lease_start_date),
        end: new Date(houseBooking.lease_end_date),
      };
    });
  }

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleChooseDatePlan = async (loc) => {
    // Validate fields
    if (!negoData) {
      const newErrors = {};

      if (!selectedStartDateWithoutNego) {
        newErrors.selectedStartDate = "*Start Date is required";
      }
      if (!selectedEndDateWithoutNego) {
        newErrors.selectedEndDate = "*End Date is required";
      }
      setCustValidErr(newErrors);

      // Check if there are any errors
      if (Object.values(newErrors).some((error) => error)) {
        return;
      }
    }
    var url = window.location.href;
    var parts = url.split("/");

    var lastPart = parts[parts.length - 1];
    var number = parseInt(lastPart);
    setIsButtonLoading(true);
    try {
      const apiRes = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/cart/add-to-cart`,
        {
          house_id: number,
          lease_start_date:
            negoData?.lease_start_date || selectedStartDateWithoutNego,
          lease_end_date:
            negoData?.lease_end_date || selectedEndDateWithoutNego,
        },
        {
          headers: headerJson,
        }
      );
      setIsButtonLoading(false);
      // if (
      //   negoData?.lease_start_date == null &&
      //   negoData?.lease_end_date == null
      // ) {
      //   localStorage.setItem("fromDate", selectedStartDateWithoutNego);
      //   localStorage.setItem("toDate", selectedEndDateWithoutNego);
      // }

      localStorage.setItem("houseId", number);
      navigate("/" + loc, {
        state: {
          houseId: number,
          cartId: apiRes?.data?.data?.cartId,
          startDate: negoData?.lease_start_date || selectedStartDateWithoutNego,
          endDate: negoData?.lease_end_date || selectedEndDateWithoutNego,
        },
      });
    } catch (err) {
      console.log(err);
      setAddToCartMsg(err?.response?.data?.message);
      localStorage.removeItem("fromDate");
      localStorage.removeItem("toDate");
      setIsButtonLoading(false);
    }
  };

  const loginAlert = () => {
    localStorage.setItem("beforeLoginPage", `/property_detail/${data.id}`);
    navigate("/login");
  };

  const amenitiesString = data.amenities;

  useEffect(() => {
    if (amenitiesString) {
      const amenitiesArray = amenitiesString.split(",");
      setAmenitiesList(amenitiesArray);
    }
  }, [amenitiesString]);


  useEffect(() => {
    if (data.utilities) {
      const jsonString = data.utilities;
      const jsonObject = JSON.parse(jsonString);

      setAllUtility(jsonObject);

      const Price = data.total_utilities_price;
      setUtiltiyPrice(Price);
    }

    const isPetAllowed = data?.is_pets_allowed;

    setAllowedPet(isPetAllowed);
  }, []);


console.log("isDatesBlockedForExtend", isDatesBlockedForExtend)

  return (
    <Fragment>
      <style>
        {`
          .ant-picker-dropdown{
            z-index: 1056;
        }


        .ant-picker.ant-picker-range.css-dev-only-do-not-override-i46qwz {
          margin: 10px 0;
      }

      .offr_price_label {
        padding-bottom: 7px;
    }
      
        `}
      </style>

      <div className="property-details">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="heading-sec">
                <h2>{data.name}</h2>
                {/* <a href="#">
                  {data?.state}, {data?.country}{" "}
                </a> */}
              </div>
            </div>
            <div
              className={`col-xs-12 col-sm-12 col-md-12 col-lg-${
                user?.currentRole === "home_owner" ? "12" : "9"
              }`}
            >
              <div className="property-about-us">
                <h4>Property Details</h4>
                <p>{data.property_details}</p>

                <p>
                  {data?.booking_details?.booking_status === "Booked"
                    ? data.full_address.replace(/[,.;:']/g, "")
                    : ""}
                </p>

                <div className="map-view-sec">
                  <span>
                    <p>Exact location provider after booking</p>
                  </span>
                </div>
              </div>
              <div className="property-about-us overview-amenities">
                <h4>Overview and Amenities</h4>
                <ul>
                  <li>
                    <img src={BedImage} alt="Bed" /> {data.bedroom_no} Bedroom
                  </li>
                  <li>
                    <img src={BathTubSvg} alt="Bathroom" /> {data.bathroom_no}{" "}
                    Bathroom
                  </li>
                  <li>
                    <img src={RulerSvg} alt="Square Feet" />
                    {data.area} SQ FT
                  </li>
                  <li>
                    {(data.house_facility === "Fully Furnished" ||
                      data.house_facility === "Customize") && (
                      <div>
                        <img src={DBed} alt="..." />
                        {data.house_facility === "Customize"
                          ? "Unfurnished"
                          : data.house_facility}
                      </div>
                    )}
                  </li>

                  {amenitiesList &&
                    amenitiesList?.length > 0 &&
                    amenitiesList.map((property, index) => (
                      <li key={index}>
                        {property === "24 Hrs water supply" && (
                          <img src={Watersupp} alt="..." />
                        )}
                        {property === "Gated security" && (
                          <img src={GatedSec} alt="..." />
                        )}
                        {property === "East side facing" && (
                          <img src={Facingside} alt="..." />
                        )}
                        {property === "Parking" && (
                          <img src={Parking} alt="..." />
                        )}
                        {property === "3rd floor" && (
                          <img src={floor} alt="..." />
                        )}
                        {property === "Pet Friendly" && (
                          <img src={LucideDog} alt="..." />
                        )}
                        {property === "Intercom" && (
                          <img src={Call} alt="..." />
                        )}
                        {property === "Air conditioner" && (
                          <img src={Snowflake} alt="..." />
                        )}
                        {property === "Internet" && (
                          <img src={Wifi} alt="..." />
                        )}
                        {property === "Elevator" && (
                          <img src={Lift} alt="..." />
                        )}
                        {property === "Power backup" && (
                          <img src={Server} alt="..." />
                        )}
                        {property === "Rain harvesting" && (
                          <img src={Rain} alt="..." />
                        )}
                        {property === "Security" && (
                          <img src={Shield} alt="..." />
                        )}
                        {property === "Park" && <img src={Trees} alt="..." />}
                        {property === "Playground" && (
                          <img src={Playground} alt="..." />
                        )}
                        {property === "Fire safety" && (
                          <img src={Fire} alt="..." />
                        )}

                        {property}
                      </li>
                    ))}
                </ul>
              </div>

              {/* // overview utility  */}

              <div className="property-about-us overview-amenities">
                <h4>Utilities</h4>
                <ul>
                  {/* <li>
                    {(data.house_facility === "Fully Furnished" ||
                      data.house_facility === "Customize") && (
                      <div>
                        <img src={DBed} alt="..." />
                        {data.house_facility === "Customize"
                          ? "Unfurnished"
                          : data.house_facility}
                      </div>
                    )}
                  </li> */}

                  {allUtiltiy &&
                    allUtiltiy?.length > 0 &&
                    allUtiltiy.map((utility, index) => (
                      <li key={index}>{utility.name}</li>
                    ))}
                </ul>
              </div>
            </div>
            {user?.currentRole === "home_owner" ? (
              ""
            ) : (
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                {data?.booking_details &&
                data?.booking_details?.booking_status === "Bookedtemp" ? (
                  <div className="bookd neogitable-sec">
                    <div>
                      <h4>Booked</h4>
                      <div className="bookd_fr">
                        <b>From: </b>
                        {format(
                          new Date(data?.booking_details?.lease_start_date),
                          "MMMM dd, yyyy"
                        )}
                      </div>
                      <div className="bookd_to">
                        <b>To: </b>
                        {format(
                          new Date(data?.booking_details?.lease_end_date),
                          "MMMM dd, yyyy"
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  // ) : negoData ? (
                  //   <div className="neogitable-sec success-neogit">
                  //     <div className="property-price-sec">
                  //       <div className="price-content">
                  //         <p>Price start at</p>
                  //         <h3>
                  //           $
                  //           {negoData?.negotiationData[0]?.owner_offered_price
                  //             ? negoData?.negotiationData[0]?.owner_offered_price
                  //             : negoData?.negotiationData[0]?.cust_offered_price}
                  //         </h3>
                  //         <span>Included all taxes and services</span>
                  //         <p className="security-fee">
                  //           + Security price : <b>${data?.security_prices}</b>
                  //         </p>
                  //       </div>
                  //       <div
                  //         style={{
                  //           position: "relative",
                  //           top: "25px",
                  //           fontSize: "0.9rem",
                  //           display: "flex",
                  //         }}
                  //       >
                  //         <i
                  //           className="fa fa-check"
                  //           style={{
                  //             color: "#009900",
                  //             position: "relative",
                  //             top: "3px",
                  //           }}
                  //         ></i>
                  //         &nbsp;
                  //         <h style={{ color: "#009900" }}>Negotiated</h>
                  //       </div>
                  //     </div>
                  //     <div className="hint-sec">
                  //       <p>
                  //         <i className="fa fa-check"></i> Negotiated Lease Period
                  //         -&nbsp;
                  //         <span style={{ color: "#009900" }}>
                  //           {(function calculateMonthsAndDays(
                  //             startDate = new Date(negoData?.lease_start_date),
                  //             endDate = new Date(negoData?.lease_end_date)
                  //           ) {
                  //             // Calculate the difference in milliseconds
                  //             let { months, remainingDays } =
                  //               calculateDateDifference(startDate, endDate);
                  //             return `${months} ${
                  //               months == 1 ? `Month` : `Months`
                  //             } ${remainingDays} ${
                  //               remainingDays == 1 ? `Day` : `Days`
                  //             }`;
                  //           })()}
                  //         </span>
                  //       </p>
                  //     </div>
                  //     <div className="hint-sec">
                  //       <p>
                  //         <i className="fa fa-check"></i> Free Pickup and Drop
                  //         servies for Site Visit
                  //       </p>
                  //     </div>
                  //     <hr />
                  //     {isLoggedIn && (
                  //       <div className="custom-action-sec">
                  //         {data.house_facility == "Fully Furnished" ? (
                  //           <button
                  //             onClick={() =>
                  //               handleChooseDatePlan("cleaning-package")
                  //             }
                  //             type="button"
                  //             className="btn btn-primary"
                  //             disabled={isButtonLoading ? true : false}
                  //           >
                  //             Customize your cleaning packages{" "}
                  //             {isButtonLoading && <Spinner />}
                  //           </button>
                  //         ) : (
                  //           <button
                  //             onClick={() =>
                  //               handleChooseDatePlan("furniture_package")
                  //             }
                  //             type="button"
                  //             className="btn btn-primary"
                  //             disabled={isButtonLoading ? true : false}
                  //           >
                  //             Pick your furniture packages{" "}
                  //             {isButtonLoading && <Spinner />}
                  //           </button>
                  //         )}
                  //       </div>
                  //     )}
                  //     {isLoggedIn == false && (
                  //       <div className="custom-action-sec">
                  //         <button
                  //           type="button"
                  //           className="btn btn-primary"
                  //           data-bs-toggle="modal"
                  //           data-bs-target="#exampleModal1"
                  //         >
                  //           Customize your home
                  //         </button>
                  //         <div
                  //           className="modal fade"
                  //           id="exampleModal1"
                  //           tabIndex="-1"
                  //           aria-labelledby="exampleModalLabel"
                  //           aria-hidden="true"
                  //         >
                  //           <div className="modal-dialog  modal-dialog-centered">
                  //             <div className="modal-content">
                  //               <div className="modal-body p-0">
                  //                 <div className="cancel_cont">
                  //                   <div className="cancel_head">
                  //                     <h2>Please Login</h2>
                  //                   </div>
                  //                   <div className="modal-body">
                  //                     <div className="negotiate-content">
                  //                       <div className="container">
                  //                         <div className="row">
                  //                           <a
                  //                             data-bs-dismiss="modal"
                  //                             onClick={() => loginAlert()}
                  //                           >
                  //                             Login
                  //                           </a>
                  //                         </div>
                  //                       </div>
                  //                     </div>
                  //                   </div>
                  //                 </div>
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     )}
                  //   </div>
                  <div className="neogitable-sec">
                    <div className="property-price-sec">
                      <div className="price-content">
                        <p>Price start at</p>
                        <h3>
                          ${data?.monthly_price + utilityPrice}/<sub>Month</sub>
                        </h3>
                        <span>Included all taxes and services</span>
                        <p className="security-fee">
                          + Security price : <b>${data?.security_prices}</b>
                        </p>
                      </div>
                      {/* &&
                        !(
                          data?.priceNegotiateRequests?.length > 0 
                          &&
                          data?.priceNegotiateRequests[0]?.status ===
                            "in_progress"
                        )  */}
                      {(user?.currentRole === "tenant" || !user) && (
                        <>
                        {isDatesBlockedForExtend   || (!data?.booking_details?.lease_start_date && !data?.booking_details?.house_price) ?
                        <div
                          style={ {}}
                          type="button"
                          className="negotiate_btn"
                          onClick={() => {
                           // if( (data?.booking_details?.lease_start_date && data?.booking_details?.house_price)) return
                            if (!user) {
                              navigate("/login");
                            } else {
                              setShowPremium(true);
                              setNegoPriceModalData(data?.monthly_price);
                            }
                          }}
                        >
                          Negotiate Price
                        </div>: null}
                        {data?.booking_details?.lease_start_date && data?.booking_details?.house_price ?
                        <NegotiationModal
                                  data={{
                                    house_id: data?.id,
                                    lease_start_date:
                                      data?.booking_details?.lease_start_date,
                                    lease_end_date: data?.booking_details?.lease_end_date,
                                    disabledDates: data?.booking_data,
                                    order_id: data?.order_id,
                                    current_lease: data?.booking_details?.house_price,
                                    house_lease:
                                      data?.monthly_price + utilityPrice,
                                  }}
                                  setIsDatesBlockedForExtend={setIsDatesBlockedForExtend}
                                />:null}
                        </>
                      )}
                    </div>

                    <div className="hint-sec">
                      <p>
                        <i className="fa fa-check"></i> *Minimum Lease Period
                        -&nbsp;
                        <span style={{ color: "red" }}>
                          {data?.min_lease_period} Months
                        </span>
                      </p>
                    </div>
                    <div className="hint-sec">
                      <p>
                        <i className="fa fa-check"></i> *All utilities are
                        included in the property price
                      </p>
                    </div>

                    <div className="hint-sec">
                      <p>
                        <i
                          className={
                            ispetAllowed
                              ? "fa fa-check"
                              : "fa-regular fa-circle-xmark"
                          }
                        ></i>
                        *{ispetAllowed ? "Pet allowed" : "Pet Not allowed"}
                      </p>
                    </div>
                    <div className="hint-sec">
                      <p>
                        <i className="fa fa-check"></i> Free Pickup and Drop
                        servies for Site Visit
                      </p>
                    </div>

                    {/* {data?.priceNegotiateRequests?.length > 0 &&
                    data?.priceNegotiateRequests[0]?.status ===
                      "in_progress" ? (
                      <div style={{ color: "#ff5c16" }}>
                        Negotiation is in progress
                      </div>
                    ) : ( */}
          {/* ------Rent housewithout nego feauture----- */}
                    {/* <>
                      <div className="booking-month">
                        <div className="book_head">
                          <h4>Select Date</h4>
                        </div>
                        <div
                          className="owl-carousel owl-theme"
                          id="yearly-slider"
                        >
                          <div className="book_field mb-3 ">
                            <RangeDateCalendar
                              size={"large"}
                              selectedStartDate={selectedStartDateWithoutNego}
                              setSelectedStartDate={
                                setSelectedStartDateWithoutNego
                              }
                              selectedEndDate={selectedEndDateWithoutNego}
                              setSelectedEndDate={setSelectedEndDateWithoutNego}
                              disabledDate={disabledDatesWithoutNego}
                              setDisabledDate={setDisabledDatesWithoutNego}
                              disabledDateRanges={disabledDateRanges}
                              disableMonths={
                                data?.min_lease_period
                                  ? data?.min_lease_period
                                  : 1
                              }
                              houseType={data?.house_facility}
                            />
                        
                          </div>
                        </div>
                      </div>

                      <div className="sap-val-error text-danger mb-0 mt-1">
                        {addToCartMsg}
                      </div>
                      
                      {isLoggedIn && (
                        <div className="custom-action-sec">
                          <div className="sap-val-error text-danger mb-0 mt-1">
                            {custValidErr.selectedStartDate}
                          </div>
                          <div className="sap-val-error text-danger mb-2 mt-1">
                            {custValidErr.selectedEndDate}
                          </div>
                          {data.house_facility == "Fully Furnished" ? (
                            <button
                              onClick={() =>
                                handleChooseDatePlan("cleaning-package")
                              }
                              type="button"
                              className="btn btn-primary"
                              disabled={isButtonLoading ? true : false}
                            >
                              Pick your cleaning packages{" "}
                              {isButtonLoading && <Spinner />}
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handleChooseDatePlan("furniture_package")
                              }
                              type="button"
                              className="btn btn-primary"
                              disabled={isButtonLoading ? true : false}
                            >
                              Pick your furniture packages
                              {isButtonLoading && <Spinner />}
                            </button>
                          )}
                        </div>
                      )}
                    </>  */}

                    {/* )} */}

                    {/* -start-  {isLoggedIn == false && (
                      <div className="custom-action-sec">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          disabled={isButtonLoading}
                        >
                          Customize your home
                          {isButtonLoading ? <Spinner></Spinner> : null}
                        </button>
                        <div
                          className="modal fade"
                          id="exampleModal1"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body p-0">
                                <div className="cancel_cont">
                                  <div className="cancel_head">
                                    <h2>Please Login</h2>
                                  </div>
                                  <div className="modal-body">
                                    <div className="negotiate-content">
                                      <div className="container">
                                        <div className="row">
                                          <a
                                            data-bs-dismiss="modal"
                                            onClick={() => loginAlert()}
                                          >
                                            Login
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}  --end-*/}
          {/* ------Rent house without nego feauture end----- */}

                
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Nego Price Modal */}
      <Modal
        show={showPremium}
        onHide={() => {
          setShowPremium(false);
          setOfferPrice("");
          setValidationErrors([]);
        }}
        className="nego-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Negotiation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label>Select Date:</label>

              <RangeDateCalendar
                size={"large"}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                disabledDateRanges={disabledDateRanges}
                // disableMonths={data?.min_lease_period ? data?.min_lease_period : 1}
                disableMonths={1}
                setDisabledDate={setDisabledDate}
                houseType={data?.house_facility}
              />
              <div className="sap-val-error text-danger mb-0 mt-1">
                {validationErrors.selectedStartDate}
              </div>

              <div className="sap-val-error text-danger mb-0 mt-1">
                {validationErrors.selectedEndDate}
              </div>
            </div>

            <div>
              {ispetAllowed && (
                <>
                  <div className="col-md-6">
                    <div className="hint-sec mb-3 ">
                      <label>Number of Pet:</label>
                      <Input
                        style={{ width: "" }}
                        type="number"
                        value={numberOfPet}
                        onChange={handleNumberOfAnimalChange}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label className="mb-2">Pet Type:</label>
                    <Select
                      isMulti
                      options={options}
                      value={selectedAnimal}
                      onChange={handleChange}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={true}
                      aria-label="Animal Type Select"
                      name="animal_type"
                      className="mb-3"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          minWidth: "400px",
                        }),
                      }}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        {selectedAnimal.find(
                          (option) => option.value === "Other"
                        ) && (
                          <label className=" mb-3">
                            Please specify:
                            <Input
                              type="text"
                              value={otherAnimal}
                              placeholder="Enter Animal Name"
                              onChange={handleOtherAnimalChange}
                            />
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div>
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="hint-sec d-flex flex-column  mb-3 ">
                    <label className=" ">Number of People:</label>
                    <Input
                      style={{ width: "" }}
                      type="number"
                      name="cust_offered_no_person"
                      value={numberOfPeople}
                      onChange={handleInputChange}
                    />
                    <div className="sap-val-error text-danger mb-0 mt-1">
                      {validationErrors.numberOfPeople}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <label className="offr_price_label pb-0">Offer Price:</label>
                  <Input
                   type="number"
                    addonBefore="$"
                    addonAfter="/month"
                    value={offerPrice}
                    onChange={(e) => setOfferPrice(e.target.value)}
                  />
                  <div className="sap-val-error text-danger mb-0 mt-1">
                    {validationErrors.offerPrice}
                  </div>
                  {/* <input
            type="text"
            value={offerPrice}
            onChange={(e) => setOfferPrice(e.target.value)}
          ></input> */}
                </div>
              </div>
            </>
          </div>

          <div className="sap-val-error text-danger mb-0 mt-1">
            {errMessage}
          </div>
          <button
            type="button"
            style={{
              borderRadius: "45px",
              // background: "rgb(4, 116, 186)",
              padding: "12px",
              display: "block",
              margin: "10px 0px 0px",
              textAlign: "center",
              fontWeight: "700",
              color: "#fff !important",
              width: "170px",
              marginLeft: "auto",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              sendNegoOffer();
            }}
            disabled={isLoader}
          >
            Send
            {isLoader ? <Spinner></Spinner> : null}
          </button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default PropertyDetailSec;
