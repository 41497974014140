import React, { useContext, useState, useEffect, useRef } from "react";
import { NotificationContext } from "../Contexts/NotificationContext";
import { UserContext } from "../Contexts/UserContext";
import { set } from "store";

export const TimeCountDown = () => {
  const { notificationList=[], setNotificationList, setIsModalVisible } =
    useContext(NotificationContext);
  const { setNegotiationId, expiryTiming, setExpiryTiming } = useContext(UserContext);



  const [countDownDate, setCountDownDate  ]= useState(null);
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });

  useEffect(() => {
    
      const currentTime = Date.now();

  // Filter notifications with expiry time greater than the current time
  const validExpiryTimes =  notificationList
    .filter(
      (data) =>
        new Date(data.expiry_time).getTime() > currentTime && data.count === 6
    )
    .map((data) => new Date(data.expiry_time).getTime());

  const validExpiryTimes1 = notificationList
    .filter(
      (data) =>
        new Date(data.expiry_time).getTime() > currentTime && data.count === 6
    )
    .map((data) => data.type_data_id);

   
 // if (validExpiryTimes.length === 0) return null;

 

    if (validExpiryTimes1.length > 0) {
    
       const expiryTime = Math.min(...validExpiryTimes);
  setCountDownDate(expiryTime);
      setNegotiationId(
        validExpiryTimes1[
          validExpiryTimes.findIndex((item) => item === expiryTime)
        ]
      );
    }
  }, [notificationList, notificationList?.length]);

//  console.log("countdown---------------->", timeLeft)
  useEffect(() => {
    
    if (expiryTiming) {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        let expiryAllNotification = notificationList
  .map((data) => {
    const updatedExpiryTime = new Date(data.expiry_time);  
       updatedExpiryTime.setTime(updatedExpiryTime.getTime() - 5 * 60 * 1000);
 
    return { ...data, expiry_time: updatedExpiryTime };
  })
  setNotificationList(expiryAllNotification)
        setCountDownDate(null)
       setExpiryTiming(false)
      return; // Stop further execution to prevent unnecessary renders
    }

    const updateTimer = () => {
      const now = Date.now();
      const distance = countDownDate - now;

      if (distance < 0 && countDownDate !==null) {
        clearInterval(timer);
        setIsModalVisible(true);
        setCountDownDate(null)

      } else if(distance < 0 ){
        setCountDownDate(null)
         clearInterval(timer);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    const timer = setInterval(updateTimer, 1000);
     updateTimer();
    return () => clearInterval(timer);
  }, [countDownDate , expiryTiming]);

  const formatTime = (time) => time.toString().padStart(2, "0");
  const textColor =
    timeLeft.minutes * 60 + timeLeft.seconds < 20 ? "#dc3545" : "#0474ba";

if(timeLeft?.minutes <= 0 && timeLeft?.seconds <= 0){
  return<></>
}

  return (
    <div
      className="countdown-section"
      style={{ backgroundColor: textColor}}
    >
      {/* {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s */}
      {formatTime(timeLeft?.minutes)}:{formatTime(timeLeft?.seconds)}
    </div>
  );
};
